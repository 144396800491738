.email {
}

.aboutMeWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.aboutMeVerticalWrapper {
  width: 25%;
  text-align: left;
  padding: 2em;
}

.aboutMeVerticalWrapper:first-child {
  padding-right: 1em;
}

.aboutMeVerticalWrapper:last-child {
  padding-left: 1em;
  width: 30%;
}

.aboutMeVerticalWrapper h2 {
  margin-top: 0;
  white-space: pre-line;
}

.aboutMeVerticalWrapper img {
  border-radius: 5px;
}

.wonminImage {
  width: 100%;
}

.socialIconsWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.socialIconsWrapper img {
  width: 50px;
  padding: 1em;
}

.madeby {
  padding: 2em;
}

.madeby p {
  font-size: 0.75em;
}

/* tablet */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .aboutMeVerticalWrapper {
    width: 40%;
  }
  .aboutMeVerticalWrapper:last-child {
    width: 40%;
  }
}

/* mobile */
@media only screen and (max-width: 900px) {
  .aboutMeWrapper {
    flex-direction: column-reverse;
  }
  .aboutMeVerticalWrapper {
    width: calc(100% - 2em) !important;
    padding: 1em !important;
    padding-bottom: 0 !important;
  }
  .socialIconsWrapper img {
    width: 40px;
    padding: 0.35em;
  }
  .madeby {
    padding: 1em;
    text-align: left;
  }
}
