.videoModalWrapper {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  display: flex;
  z-index: 99999;
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.videoModalCloseButtonWrapper {
  width: calc(100% - 2em);
  max-width: 1000px;
  display: flex;
  justify-content: flex-end;
}

.videoWrapper {
  border-radius: 4px;
  overflow: hidden;
}

@media only screen and (max-width: 900px) {
  .videoModalWrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .videoModalCloseButtonWrapper {
    justify-content: center;
    margin-top: 0;
  }
}
