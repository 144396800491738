/* #region -------------------------------------------------- FLIP CARD */

.flipcard {
  background-color: transparent;
  display: inline-block;
  position: relative;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
  -webkit-perspective: 1000px; /* Remove this if you don't want the 3D effect */
  -moz-perspective: 1000px; /* Remove this if you don't want the 3D effect */
  -o-perspective: 1000px; /* Remove this if you don't want the 3D effect */
  pointer-events: all;
  font-family: "Roboto Regular";
}

.flipcardInner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
}

.flipcard:not(.disable-flip):not(.is-clicked):hover .flipcardInner,
.flipcard.is-flipped .flipcardInner {
  transform: rotateY(180deg);
}

.flipcard.is-flipped:not(.disable-flip):not(.is-clicked):hover .flipcardInner {
  transform: rotateY(0deg);
}

.flipcard:not(.disable-shadow) .flipcardBack,
.flipcard:not(.disable-shadow) .flipcardFront {
  box-shadow: 0px 0px 15px rgb(0 0 0 / 33%);
}

.flipcardFront,
.flipcardBack {
  position: absolute;
  border-radius: 2em;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backface-visibility: hidden; /* Safari */
  -moz-backface-visibility: hidden; /* Safari */
  -o-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.flipcardBack {
  transform: rotateY(180deg);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipcardBack.name {
  background-color: #323232;
}

/* #endregion ----------------------------------------------------------- */

/* #region ------------------------------------------------ MEMBER CARD */

.memberCommCardWrapper {
  height: 100%;
  width: 100%;
}

.memberCommCardText {
  width: calc(100% - 4em);
  position: absolute;
  bottom: 0;
  text-align: left;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  color: #fff;
  margin: 2em;
  z-index: 9;
}

.memberCommCardMainText {
  font-size: 4em;
  line-height: 100%;
  font-family: "Roboto Regular";
}

.memberCommCardSubText {
  font-size: 2.5em;
  line-height: 100%;
  font-family: "Roboto Light";
}

.memberCommCardShadow {
  height: 50%;
  width: 100%;
  position: absolute;
  bottom: -1px;
  background-image: linear-gradient(rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.75));
}

.memberCommCardFullShadow {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
}

.memberCommCardQuestionMark {
  color: #fff;
  font-size: 40em;
  position: absolute;
  margin: 0;
  font-family: "Rowdies-Light";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}

.memberCommCardImage {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center center;
}

/* #endregion ----------------------------------------------------------- */

/* #region -------------------------------------------EVENT / GOAL CARD */

.eventGoalCardWrapper {
  font-family: "Futura", "Roboto Regular";
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.eventGoalTextWrapper {
  color: #323232;
  font-size: 3.5em;
  line-height: 125%;
  text-align: left;
  overflow: hidden;
  border-radius: 0.25em;
  margin: auto;
  background: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eventGoalTextWrapper.location {
  height: calc(100% - 2.5em);
  padding: 1.25em;
}

.eventGoalTextWrapper.event {
  width: calc(100% - 3em);
  padding: 1.5em;
}

.eventGoalCardWrapper img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  left: 50%;
  top: 50%;
}

.eventGoalCardWrapper img.event {
  transform: translate(-50%, -50%) scale(1.1);
}

.eventGoalCardWrapper img.location {
  transform: translate(-50%, -50%) scale(1.05);
}

@media only screen and (max-width: 900px) {
  .eventGoalBottomTextWrapper {
    padding-top: 1em !important;
    padding-bottom: 1em !important;
  }
  .footer .eventGoalTextWrapper {
    font-size: 3.5em;
    font-family: "Rowdies-Light";
  }
  .eventGoalDecksContainer .eventGoalTextWrapper {
    font-size: 2.5em;
  }
}

.eventGoalBottomTextWrapper.yellowBackground {
  color: #323232;
}

.eventGoalBottomTextWrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  color: #fff;
  width: calc(100% - 4em);
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
  margin: auto;
  font-size: 1em;
}

/* #endregion ----------------------------------------------------------- */

/* #region -------------------------------------------------- CARD BACK */

.cardBack {
  width: 100%;
}

.cardBack img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* #endregion ----------------------------------------------------------- */

/* #region -------------------------------------------------- CARD BACK NAME */

.cardBackText {
  -webkit-font-smoothing: antialiased;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-family: "Rowdies-Bold";
  padding-left: 1em;
  padding-right: 1em;
}

.cardBack.name {
  width: calc(100% - 2em);
  height: calc(100% - 2em);
  border-radius: 2em;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardBackName {
  font-size: 3em;
  margin-top: 0;
  line-height: 125%;
  margin-bottom: 0.5em;
}

.cardBackTitle {
  font-size: 1.5em;
  margin-top: 0;
  text-transform: uppercase;
}

.cardBackAge,
.cardBackJob {
  font-size: 1.5em;
  margin: 0;
  text-transform: lowercase;
}

/* #endregion ----------------------------------------------------------- */
