.parallaxSection {
  height: 300svh;
  overflow-x: hidden;
  z-index: 1;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.parallaxRow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.parallaxWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0.5em;
  will-change: transform, opacity;
}

.parallaxWrapper.missingCard {
  opacity: 0;
}
