/* #region---------------------------------------------------------PAGE */

.characterContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .characterContent .defaultButton {
    margin: auto;
    margin-bottom: 2em;
    width: calc(100% - 2em);
  }
}

.moretocome {
  font-family: "Rowdies-Regular";
  font-size: 2em;
}

.freeEmojiButtonWrapper {
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

.freeEmojiButtonWrapper a {
  color: #323232;
  font-family: "Rowdies-Regular";
}

.freeEmojiButtonWrapper img {
  width: 32px;
  height: 32px;
  margin: 0.5em;
}

/* #endregion */

/* #region---------------------------------------------------------SPOTLIGHT */

.characterSpotlightContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
}

.characterSpotlightContainer > .characterSpotlightWrapper {
  padding: 0;
  margin-bottom: 1em;
  border: 0.5em solid #323232;
  border-radius: 5px;
}

.characterSpotlightContainer .spotlightRightTopWrapper {
  padding: 0;
  max-width: 500px;
}

.characterButtonsWrapper {
  width: calc(1000px + 9em);
  display: flex;
  justify-content: space-between;
  margin-bottom: 10vh;
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .characterButtonsWrapper {
    width: calc(600px + 9em);
  }
}

@media only screen and (max-width: 900px) {
  .characterSpotlightContainer > .characterSpotlightWrapper {
    width: 100%;
  }
  .characterButtonsWrapper {
    flex-direction: column;
    width: calc(100% - 0.75em);
    margin-left: -0.5em !important;
    margin: auto;
  }
  .characterButtonsWrapper a:not(:last-child) {
    margin-bottom: 1em;
  }
  .characterSpotlightContainer .spotlightLeftWrapper {
    width: calc(100% - 4em);
  }
}

/* #endregion */

/* #region---------------------------------------------------------ALL CHARACTERS */

.allcharactersWrapper {
  padding-bottom: 2em;
}

.charactersContainer {
  width: calc(100% - 2em);
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  flex-wrap: wrap;
  min-height: 100vh;
  justify-content: center;
  gap: 1em;
}

@media only screen and (max-width: 900px) {
  .charactersContainer {
    justify-content: space-between;
  }
}

.characterWrapper {
  width: calc(20% - 1em);
  min-height: 250px;
  position: relative;
  box-shadow: 0 12.5px 25px -15px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.characterWrapper:not(.notDone):hover {
  z-index: 99;
}

@media only screen and (min-width: 800px) {
  .characterWrapper:not(.notDone):hover .characterInnerWrapper {
    transform: scale(1.25);
  }
}

.characterWrapper:not(.notDone):hover .caption {
  opacity: 1;
  bottom: 0px;
}

.characterWrapper:not(.notDone) {
  cursor: pointer;
}

.characterInnerWrapper {
  transition: transform 0.25s;
  border-radius: 0.5em;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.characterWrapper {
  border-radius: 0.5em;
}

.characterWrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.caption {
  opacity: 0;
  margin: 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), #323232);
  color: white;
  position: absolute;
  width: 100%;
  bottom: -150px;
  transition: bottom 0.25s, opacity 0.25s;
  text-align: center;
}

.caption p {
  line-height: 100% !important;
  font-family: "Rowdies-Regular";
  margin: 0;
  padding: 10px;
}

.caption > .name {
  font-size: 2em;
  border-bottom: 1px solid #fff;
}

.caption > .details {
  font-family: "Rowdies-Light";
  font-size: 1em;
}

.caption > .details > span {
  padding-right: 5px;
}

/* phone */
@media only screen and (max-width: 900px) {
  .characterWrapper:not(.notDone) .caption {
    opacity: 1;
    bottom: 0px;
  }

  .characterWrapper {
    width: calc(50% - 1em);
    height: 100px !important;
    font-size: 0.6em;
  }

  .caption > .name {
    border-bottom: 0;
  }
  .caption {
    border-radius: 0.5em;
  }
}

/* larger phone */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .characterWrapper {
    width: calc(33% - 1em);
    min-height: 198px !important;
    font-size: 0.9em;
  }
}

/* tablet */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .characterWrapper {
    width: calc(25% - 1em);
    min-height: 126px !important;
    font-size: 1em;
  }
}

/* #endregion */
