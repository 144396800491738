/* #region--------------------------------------GENERAL */

.mainContentWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #323232;
}

.mainpageContainer {
  position: relative;
  display: flex;
}

.mainpageContainer h1 {
  font-size: 2.5em;
}

.mainpageContainer p.noMargin {
  margin: 0;
}

/* mobile */
@media only screen and (max-width: 900px) {
  .mainpageContainer h1 {
    font-size: 1.25em;
    line-height: 125%;
  }
  .mainpageContainer p,
  .mainpageContainer li {
    font-size: 0.85em;
    line-height: 150%;
  }
}

/* #endregion */

/* #region--------------------------------------HERO IMAGE */

#shootingStarsContainer {
  position: absolute;
  z-index: 1;
  height: 100svh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#shootingStarsWrapper {
  width: 100vw;
  height: 500px;
  position: relative;
}

#heroContainer {
  width: 100%;
  height: 100svh;
}

#heroBgImage {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  background-image: url("images/bg_seamless.webp");
  background-repeat: repeat;
}

.screenHeight {
  height: 100svh; /* Modern browsers handle dynamic resizing correctly */
  width: 100%;
  position: relative;
  text-align: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@keyframes monsterBurstAnimation1 {
  0%,
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1) skew(0deg, 0deg);
  }
  62% {
    transform: translate(-50.7%, -49.7%) scale(1.02) skew(-1.5deg, 1.5deg);
  }
  64% {
    transform: translate(-49.3%, -50.3%) scale(1) skew(1.5deg, -1.5deg);
  }
  66% {
    transform: translate(-50.5%, -50.5%) scale(1.02) skew(-1.5deg, 1.5deg);
  }
  68% {
    transform: translate(-49.5%, -49.5%) scale(1) skew(1.5deg, -1.5deg);
  }
  70% {
    transform: translate(-50.7%, -49.7%) scale(1.02) skew(-1.5deg, 1.5deg);
  }
  72% {
    transform: translate(-49.3%, -50.3%) scale(1) skew(1.5deg, -1.5deg);
  }
  74% {
    transform: translate(-50.5%, -50.5%) scale(1.02) skew(-1.5deg, 1.5deg);
  }
}

@keyframes monsterBurstAnimation2 {
  40%,
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1) skew(0deg, 0deg);
  }
  62% {
    transform: translate(-50.6%, -49.6%) scale(1.01) skew(-1.4deg, 1.4deg);
  }
  64% {
    transform: translate(-49.4%, -50.4%) scale(1) skew(1.4deg, -1.4deg);
  }
  66% {
    transform: translate(-50.4%, -50.4%) scale(1.01) skew(-1.4deg, 1.4deg);
  }
  68% {
    transform: translate(-49.6%, -49.6%) scale(1) skew(1.4deg, -1.4deg);
  }
  70% {
    transform: translate(-50.6%, -49.6%) scale(1.01) skew(-1.4deg, 1.4deg);
  }
  72% {
    transform: translate(-49.4%, -50.4%) scale(1) skew(1.4deg, -1.4deg);
  }
  74% {
    transform: translate(-50.4%, -50.4%) scale(1.01) skew(-1.4deg, 1.4deg);
  }
}

@keyframes monsterBurstAnimation3 {
  0%,
  30%,
  60% {
    transform: translate(-50%, -50%) scale(1) skew(0deg, 0deg);
  }
  62% {
    transform: translate(-50.8%, -49.8%) scale(1.03) skew(-1.6deg, 1.6deg);
  }
  64% {
    transform: translate(-49.2%, -50.2%) scale(1) skew(1.6deg, -1.6deg);
  }
  66% {
    transform: translate(-50.6%, -50.6%) scale(1.03) skew(-1.6deg, 1.6deg);
  }
  68% {
    transform: translate(-49.4%, -49.4%) scale(1) skew(1.6deg, -1.6deg);
  }
  70% {
    transform: translate(-50.8%, -49.8%) scale(1.03) skew(-1.6deg, 1.6deg);
  }
  72% {
    transform: translate(-49.2%, -50.2%) scale(1) skew(1.6deg, -1.6deg);
  }
  74% {
    transform: translate(-50.6%, -50.6%) scale(1.03) skew(-1.6deg, 1.6deg);
  }
}

.monsterBurstAnimation {
  animation: monsterBurstAnimation1 5s infinite,
    monsterBurstAnimation2 2s infinite, monsterBurstAnimation3 5s infinite,
    monsterBurstAnimation2 3s infinite;
}

.heroImage {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 1000px;
  z-index: 50;
  transform: translate(-50%, -50%);
}

.heroTitleWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 450px) {
  .fukidashi {
    width: 110svw;
    height: 20svh;
  }
  .heroTitle {
    height: 25svh;
  }
}

@media only screen and (max-width: 600px) and (min-width: 450px) {
  .fukidashi {
    width: 110svw;
    height: 15svh;
  }
  .heroTitle {
    height: 20svh;
  }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .fukidashi {
    width: 110svw;
    height: 10svh;
  }
  .heroTitle {
    height: 15svh;
  }
}

.heroTitle {
  padding: 1em;
  margin: 0;
  display: flex;
  justify-content: center;
  width: calc(100% - 2em);
}

@media only screen and (max-width: 900px) {
  .heroTitle {
    position: absolute;
    transform: translateY(-25%);
    align-items: flex-end;
    top: 0;
    background-color: #fff;
    text-wrap: balance;
  }
  .heroTitleWrapper {
    margin-top: 12svh;
  }
}

@media only screen and (min-width: 900px) {
  .heroTitleWrapper {
    max-width: 500px;
    position: relative;
    margin-top: 8svh;
  }
  .fukidashi {
    max-width: 500px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 25;
  }
  .heroTitle {
    max-width: 500px;
    z-index: 26;
    transform: translateY(-30%);
    align-items: center;
    font-size: 1.75em;
  }
}

.heroImage > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.tagLine {
  font-size: 1.5em;
  white-space: pre-wrap;
  line-height: 70%;
  margin-bottom: 4em;
  width: 100%;
}

.heroBottomWrapper {
  z-index: 100;
  position: absolute;
  bottom: 1em;
  margin-bottom: 3em;
  left: 50%;
  color: #fff;
  width: calc(100% - 2em);
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.justKeepScrolling {
  max-width: 150px;
}

/* mobile */
@media only screen and (max-width: 900px) {
  .heroBottomWrapper {
    position: fixed;
    bottom: 1em;
    margin-bottom: 0;
  }
  .heroImage {
    padding-top: 0;
  }
  .tagLine {
    line-height: 120%;
    margin: 0;
    margin-bottom: 1em;
    margin-top: 4em;
    font-size: 1.25em;
  }
}

@media only screen and (max-height: 650px) {
  .justKeepScrolling {
    display: none;
  }
  .heroTitle {
    display: none;
  }
  .fukidashi {
    display: none;
  }
}

/* #endregion */

/* #region--------------------------------------TITLE MASK */

.titleWrapper.topOne {
  position: absolute;
  left: 55%;
  top: 50px;
}

.titleWrapper.topOne {
  height: 300px;
  width: 300px;
}

.titleWrapper.botOne {
  height: 500px;
  width: 500px;
}

@media only screen and (max-width: 900px) {
  .titleWrapper.botOne {
    width: 350px !important;
    height: 350px !important;
  }
}

/* mobile */
@media only screen and (max-width: 900px) {
  .titleWrapper:not(.botOne) {
    position: static !important;
    height: 100% !important;
  }
}

/* mobile widths */
@media only screen and (min-width: 800px) and (max-width: 900px) {
  .titleWrapper:not(.botOne) {
    width: 250px !important;
  }
}
@media only screen and (min-width: 700px) and (max-width: 800px) {
  .titleWrapper:not(.botOne) {
    width: 300px !important;
  }
}
@media only screen and (max-width: 700px) {
  .titleWrapper:not(.botOne) {
    width: 350px !important;
  }
}

/* low height */

@media only screen and (min-height: 800px) and (max-height: 900px) {
  .titleWrapper:not(.botOne) {
    width: 250px !important;
  }
}
@media only screen and (min-height: 630px) and (max-height: 800px) {
  .titleWrapper:not(.botOne) {
    width: 150px !important;
  }
}
@media only screen and (max-height: 630px) {
  .titleWrapper:not(.botOne) {
    display: none;
  }
}

.lcmMask {
  -webkit-mask-image: url("images/lcm.svg");
  mask-image: url("images/lcm.svg");
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-size: 75%;
  mask-position: center;
  width: 100%;
  height: 100%;
}

.lcmMask:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  left: -25%;
  top: -25%;
  z-index: -1;
  background: url("images/background watercolor.webp");
  background-repeat: repeat;
  background-size: 100% 100%;
  background-origin: center center;
  animation: rotation 15s linear infinite;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* #endregion */

/* #region--------------------------------------SHAREHOME DESCRIPTION */

#descriptionContainer {
  margin-top: 100svh;
  min-height: 100svh;
  justify-content: center;
  align-items: center;
  color: #323232;
  margin-bottom: 0;
  z-index: 20;
}

.bulletWrapper {
  max-width: 400px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.descriptionOpaque {
  opacity: 0;
}

.descriptionWrapper {
  margin-top: 4em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: calc(100% - 2em);
}

.descriptionButtonWrapper {
  display: flex;
  gap: 1em;
  justify-content: center;
}

.descriptionWrapper > div {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.descriptionTextWrapper > a {
  margin: auto;
  margin-top: 1em;
}

.subtitle {
  font-size: 2em;
  white-space: pre-line;
  font-family: "Rowdies-Regular";
  margin: 0 !important;
}

.gameDetails {
  font-size: 1.25em;
  margin-top: 1em;
}

.gameDetails > div {
  display: flex;
}

.gameDetails > div > svg {
  margin-top: 2px;
}

#howToPlay {
  height: 200px;
  margin: auto;
  margin-top: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* mobile */
@media only screen and (max-width: 900px) {
  .bulletWrapper {
    max-width: unset;
  }
  .gameDetails {
    font-size: 0.65em !important;
  }
  .gameDetails img {
    width: 25px;
    height: 25px;
  }
  .subtitle {
    font-size: 1.25em !important;
  }

  .gameDetails > div > svg {
    margin-top: -3px !important;
  }

  #descriptionContainer {
    margin-bottom: -20svh;
  }

  .descriptionTextWrapper {
    width: calc(100% - 2em);
  }
}

/* #endregion */

/* #region--------------------------------------CHARACTER DECKS  */

#characterContainer {
  color: #323232;
  display: flex;
  height: 100svh;
  width: 100%;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
}

.characterTextContainer {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: calc(100% - 2em);
  width: 40%;
  padding: 2em;
  z-index: 9;
}

.characterTextContainer h1 {
  line-height: 125%;
  max-width: 70%;
}

.characterContainerSVG {
  position: absolute;
  top: 150px;
  left: 700px;
}

.characterContainerSVG p {
  text-align: right;
  transform: rotate(-25deg);
  margin-right: -2em;
  margin-top: 1em;
  text-align: right;
  font-size: 0.85em;
}

/* small desktop */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .characterTextContainer h1,
  .eventsTextContainer h1,
  .eventsTextContainer p,
  .goalsTextContainer h1,
  .goalsTextContainer p {
    max-width: 100% !important;
  }
}

/* tablet */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .characterContainerSVG {
    left: 40vw;
  }
  .characterTextContainer h1,
  .eventsTextContainer h1,
  .eventsTextContainer p,
  .goalsTextContainer h1,
  .goalsTextContainer p {
    max-width: 100% !important;
  }
}

/* mobile */
@media only screen and (max-width: 900px) {
  .characterTextContainer h1,
  .eventsTextContainer h1,
  .eventsTextContainer p,
  .goalsTextContainer h1,
  .goalsTextContainer p {
    max-width: 100% !important;
  }
  .characterTextContainer {
    width: calc(100% - 2em);
    padding: 1em;
    margin: auto;
    max-width: 500px;
    text-align: center;
  }
  .characterContainerSVG {
    top: 15%;
    left: 60%;
  }
}

/* #endregion */

/* #region--------------------------------------EVENTS DECKS  */

#eventsContainer,
#goalsContainer {
  color: #323232;
  display: flex;
  height: 100svh;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.eventsLeftContainer,
.goalsRightContainer {
  display: flex;
  text-align: right;
  flex-direction: row;
  align-items: center;
  height: calc(100% - 4em);
  width: 50%;
  padding: 2em;
  padding-right: 4em;
  z-index: 10;
}
.eventsLeftContainer {
  justify-content: flex-end;
}
.goalsRightContainer {
  justify-content: flex-start;
}

.eventsTextContainer,
.goalsTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.eventsTextContainer {
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  text-wrap: balance;
}
.goalsTextContainer {
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  text-wrap: balance;
}

.eventsTextContainer h1,
.goalsTextContainer h1 {
  line-height: 125%;
  max-width: 70%;
}

.eventsTextContainer p,
.goalsTextContainer p {
  white-space: pre-line;
  max-width: 80%;
}

.eventsContainerSVG,
.goalsContainerSVG {
  position: absolute;
}

#eventsContainerSVGcake {
  top: 69%;
  right: 20%;
}

#eventsContainerSVGlovekey {
  top: 15%;
  right: 25%;
}

#eventsContainerSVGplanet {
  top: 20%;
  left: 20%;
  transform: scale(0.65);
}

#goalsContainerSVGflag {
  top: 58%;
  right: 27%;
  transform: scale(0.65);
}

#goalsContainerSVGscroll {
  top: 24%;
  right: 24%;
  transform: scale(0.65);
}

/* small desktop */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  #eventsContainerSVGcake {
    right: 20%;
    top: 65%;
  }
  #eventsContainerSVGlovekey {
    right: 22%;
  }
  #goalsContainerSVGflag {
    right: 14%;
  }
  #goalsContainerSVGscroll {
    right: 17%;
  }
}

/* tablet */
@media only screen and (min-width: 900px) and (max-width: 1200px) {
  #eventsContainerSVGcake {
    right: 65%;
    top: 65%;
  }
  #eventsContainerSVGlovekey {
    right: 6%;
  }
  .eventsTextContainer {
    max-width: 400px;
  }
  #goalsContainerSVGflag {
    right: 70%;
    top: 70%;
  }
  #goalsContainerSVGscroll {
    right: 70%;
    top: 17%;
  }
}

/* mobile */
@media only screen and (max-width: 900px) {
  .eventsTextContainer,
  .goalsTextContainer {
    width: 100%;
    margin: auto;
    max-width: 500px;
    text-align: center;
    align-items: center;
  }
  #likehateContainer,
  .goalsTextContainer,
  .eventsTextContainer {
    text-wrap: unset !important;
  }
  #eventsContainerSVGcake {
    top: 70%;
    right: 5%;
  }
  #eventsContainerSVGlovekey {
    top: 20%;
    right: 10%;
  }
  #eventsContainerSVGplanet {
    top: 75%;
    left: -5%;
  }
  .goalsRightContainer {
    width: calc(100% - 2em);
    padding-left: 1em;
    padding-right: 1em;
  }
  .eventsLeftContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: calc(100% - 2em);
  }
  #goalsContainerSVGflag {
    right: 15%;
    top: 70%;
  }
  #goalsContainerSVGscroll {
    right: 7.5%;
    top: 5%;
  }
  .characterContainerSVG > img,
  #goalsContainerSVGflag > img,
  #goalsContainerSVGscroll > img,
  #eventsContainerSVGlovekey > img,
  #eventsContainerSVGcake > img,
  #eventsContainerSVGplanet > img {
    transform: scale(0.65);
  }
}

/* #endregion */

/* #region--------------------------------------CAROUSEL  */

.carouselContainer {
  z-index: 10;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2em;
}

.mainpageCarousel {
  margin-bottom: 0;
}

.carouselTextContainer {
  padding: 2em;
}

@media only screen and (max-width: 900px) {
  .carouselTextContainer {
    padding: 1em;
    width: calc(100% - 2em);
  }
  .mainpageCarouselTitle {
    margin-top: 0;
  }
}

/* #endregion */

/* #region--------------------------------------LIKES / HATES  */

#likehateContainer {
  color: #323232;
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-wrap: balance;
}

@media only screen and (max-width: 1400px) {
  #likehateContainer {
    margin-top: 0;
    flex-direction: column;
  }
}

/* #endregion */

/* #region--------------------------------------MECHANICS CONTAINER */

#mechanicsContainer {
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
  width: calc(100% - 4em);
}

.mechanicsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.mechanicsWrapper div {
  padding: 0em;
  font-size: 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Rowdies-Light";
}

.mechanicsWrapper div > img {
  width: 200px;
  height: 200px;
  margin-right: 1em;
  margin-left: 1em;
}

.rulebookWrapper {
  margin-top: 10em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rulebookWrapper > a {
  margin-left: 1em;
  margin-right: 1em;
}

@media only screen and (max-width: 900px) {
  .mechanicsWrapper li {
    padding: 0.25em;
    font-size: 1.25em;
  }

  .mechanicsWrapper div > img {
    width: 125px;
    height: 125px;
    margin-right: 0.35em;
    margin-left: 0.35em;
  }

  .rulebookWrapper {
    flex-direction: column;
    margin-top: 10em;
    margin-bottom: 0em;
    width: 100%;
  }

  .rulebookWrapper > a {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 0;
    margin-right: 0;
    width: calc(100% - 2em);
  }
}

/* #endregion */

/* #region--------------------------------------SPOTLIGHT CONTAINER */

#spotlightContainer {
  display: flex;
  flex-direction: column;
}

#spotlightContainer > div > div > h1 {
  margin-bottom: 0 !important;
}

/* #endregion */

/* #region--------------------------------------FINAL CONTAINER */

#finalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
}

.finalButtonsContainer {
  padding: 0;
  margin-bottom: 25px;
  width: calc(100% - 4em);
}

.finalButtonsWrapper {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .finalButtonsContainer {
    width: calc(100% - 2em);
  }
  .finalButtonsWrapper {
    flex-direction: column;
    gap: 1em;
  }
  #finalContainer {
    padding-bottom: 100px;
  }
}

.finalButtonsWrapper > div,
.finalButtonsWrapper > a {
  margin-right: 2em;
}

.finalButtonsWrapper > a,
.finalButtonsWrapper > div {
  font-size: 1em;
}

.termsWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.termsWrapper p {
  font-size: 0.75em;
}

.pegasusWrapper {
  display: flex;
  gap: 0.25em;
  justify-content: center;
  align-items: center;
}

.pegasusWrapper > p {
  margin: 0;
}

.pegasusWrapper > img {
  width: 50px;
}

/* #endregion */

/* #region TTRPG page specific */

.textWrapper {
  max-width: 600px !important;
  text-wrap: unset;
}
