.footer {
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 25vh;
  overflow: hidden;
  padding-top: 25vh;
  pointer-events: none;
}
